body {
  background-color: #f1f1f2;
  color: #111111;
  font-family: "Work Sans", sans-serif;
}

.shadowlg {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 7px;
}
.regi-form {
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.12);
}
.fs27 {
  font-size: 27px;
  font-weight: 600;
  display: inline-block;
}
.fs20 {
  font-size: 20px;
  font-weight: 600;
}
.footer {
  padding: 24px 0;
  z-index: 10;
  bottom: 0;
  width: 100%;
  margin-top: 20px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: relative;
  font-size: 14px;
  color: rgb(65, 64, 66);
  line-height: 24px;
}
.header {
  height: 70px;
}
.header {
  transition: all 0.5s ease-in-out 0s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 7px;
}
.header.scrolled {
  position: fixed;
  padding: 5px 25px;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100% !important;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 7px;
  right: 0;
  min-width: 100%;
}
@media only screen and (max-width: 767px) {
}
